$font_anek: "Anek Devanagari", sans-serif;
$font_karla: "Karla", sans-serif;
$font_mont: "Montserrat", sans-serif;
$font_roboto: "Roboto", sans-serif;
$black1: #1f1f1f;
$black2: #2f2f2f;
$black3: #3f3f3f;
$main_green: #26b33e;
$light_green: #6ef188;
$dark_green: #146622;
$yellow:#FFFF00;

$purple: rgb(123, 31, 162);
$violet: rgb(103, 58, 183);
$pink: rgb(244, 143, 177);
