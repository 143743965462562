@keyframes fadeInUp {
    from {
        transform: translate3d(0,50%,0)
    }

    to {
        transform: translate3d(0,0,0);
        opacity: 1
    }
}

.buy-us-a-coffee {
  position: fixed;
  bottom: 30px;
  left: 30px;
  height: 50px;
  user-select: none;
  cursor: pointer;
  scroll-behavior: smooth;
  padding: 10px;
  z-index: 999;
  box-sizing: content-box;
  background-color: #FFDD00;
  border-radius: 15px;
  border: 4px solid white;

  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeInUp 1s;

  a {
    color: black;
    text-decoration: none;
  }

  #close {
    position: absolute;
    background-color: red;
    border: 1px solid gray;
    right: 3px;
    top: 3px;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    overflow: hidden;
    font-size: 12px;
    z-index: 10;

    &::hover {
      background-color: lightred;
    }
  }

  #help-us-out {
    position: absolute;
    bottom: 106%;
    left: 0;
    height: 100px;
  }

  @media(max-width: 700px) {
    scale: 75%;
    left: 10px;
    bottom: 10px;
  }
}

