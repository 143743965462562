@import "../utils/styles/variabiles";

.apps {
  height: 100vh;
  // min-height: 100vh;
  padding: 0;
  margin: 0;
  transition: background-color 1000ms;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  overflow: hidden;
}

.card {
  background-size: cover;
  background-position: center;
  position: relative;
  cursor: pointer;
  outline: none;
  transition: scale 100ms;
  text-align: center;
  font-weight: 800;
  font-family: inherit;
  font-stretch: extra-expanded;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.6);

  .card-front-image {
    position: relative;
    z-index: 2;
  }

  .card-image {
    width: clamp(300px, 20vw, 500px);
    // width: min(40%, 500px);
    aspect-ratio: 9 / 16;
    border-radius: clamp(0.5rem, 0.75vw, 2rem);
  }

  h3 {
    color: whitesmoke;
    font-size: 1.2em;
    font-family: inherit;
    transform: translateY(-100%);
    opacity: 0;
    transition: opacity 1s;
    transition: all 1s ease;
    position: absolute;
  }

  &:hover {
    .card-faders {
      opacity: 0.4;
    }

    h3 {
      transform: translateY(0);
      opacity: 1;
    }
  }
}

.card-faders {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 1;
  opacity: 0;
  transition: opacity 1500ms;

  pointer-events: none
}

.app1{
  width: 10vw;
  height: auto;
  top: calc(20vh - 10vh);
  right: calc(-4vw + 2vw);
}
.app2{
  width: 5vw;
  height: auto;
  top: calc(40vh - 10vh);
  right: calc(-14vw + 2vw);
}
.app3{
  width: 5vw;
  height: auto;
  top: calc(40vh - 10vh);
  left: calc(-14vw + 2vw);
}

.card:active {
  scale: 0.94;
}

.card-fader {
  position: absolute;
  left: 0px;
  top: 0px;
  animation: spread 3s linear infinite;
}

.card-fader:is(:nth-child(2)) {
  animation-delay: 750ms;
}

.card-fader:is(:nth-child(3)) {
  animation-delay: 1500ms;
}

.card-fader:is(:nth-child(4)) {
  animation-delay: 2250ms;
}

@media(max-width: 1200px) {
  body {    
    justify-content: flex-start;
    align-items: flex-start;
  }

  .apps {
    flex-direction: column; 
    align-items: center;
    gap: 4rem;
    padding: 4rem;
  }

  .card .card-image {
    width: 400px;    
  }
}

@media(max-width: 1200px) {
  .apps {
    gap: 2rem;
    padding: 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: space-around;
    gap: 5%;
    font-size: 0.75em;
    height: 80vh;

    h3 {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .card .card-image {
    width: 100%;    
  }
}

@keyframes spread {
from {
  transform: scale(1, 1);
  opacity: 1;
}
to {
  transform: scale(1.3, 0.7);
  opacity: 0;
}
}
