@import './variabiles';

.navigation {
  user-select: none;
  z-index: 9999;
  height: 70px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  background: rgba(40, 40, 40, 0.5);
  border-radius: 12px;
  border-style: groove;
  border-color: black;
  box-shadow: 0 1px 15px rgb(0, 0, 0);
  backdrop-filter: blur(15px);
  margin: 10px auto;
}

#nav-container {
  max-width: 100vw;
  margin: 0 auto;
}

.headerButton {
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  font-weight: 800;
  line-height: 1;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  color: #ccc;
  padding: 15px 20px;
  border: 1px solid rgba(61, 61, 61, 0.492);
  border-radius: 12px;
  position: relative;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  overflow: hidden;
  text-decoration: none;
  transition: transform 0.3s ease, background-color 0.3s ease, color 0.3s ease;
}

.headerButton:hover {
  text-decoration: none;
  font-weight: bolder;
  border: 1px solid rgba(71, 71, 71, 0.774);
  text-shadow: 0 0 10px #00ff44;
  transform: scale(1.1) translateY(-2px);
}

@media screen and (min-width: 1270px) {
  .headerButton::before {
    content: "";
    position: absolute;
    top: 4px;
    left: 4px;
    right: 4px;
    bottom: 4px;
    border: 1px solid rgba(71, 71, 71, 0.69);
    border-radius: 12px;
    pointer-events: none;
  }
}

@media only screen and (max-width: 1270px) {
  .headerButton {
    padding: 10px 15px;
    font-size: 14px;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.787);
  }

  .headerButton:hover {
    transform: scale(1.05) translateY(-1px);
  }
}

.brand {
  position: absolute;
  gap: 20px;
  color: white;
  float: left;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .headers {
    font-size: 0.9rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  a, a:visited {
    color: white;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;

  }

  img {
    height: 70px;
    padding: 10px;
    aspect-ratio: 1;
    margin: auto;
    position: relative;
  }
}

nav {
  float: right;
  ul {
    list-style: none;
    margin: 10px;
    padding: 0;
    gap:10px;
    li {
      position: relative;
      a {
        display: block;
        padding: 10px 20px 0 20px;
        line-height: 50px;
        background: none;
        color: white;
        text-decoration: none;

      
      }
      ul li {
        min-width: 190px;
        a {
          padding: 15px;
          line-height: 20px;
        }
      }
    }
  }
}

.nav-dropdown {
  display: none;
  position: absolute;
  z-index: 1;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.15);
}

.nav-mobile {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  background: none;
  height: 70px;
  width: 70px;
}

@media only screen and (max-width: 1270px) {
  .nav-mobile {
    display: block;
  }
  nav {
    width: 100%;
    padding: 70px 25px 15px;
    ul {
      display: none;
      width: 100vw;
      left: -40px;
      position: relative;

      li {
        float: none;

        a {
          padding: 15px;
          line-height: 20px;

          &:hover::before {
            width: 20%;
          }
        }
        ul li a {
          padding-left: 30px;
        }
      }
    }
  }
  .nav-dropdown {
    position: static;
  }
}

#nav-toggle {
  position: absolute;
  left: 0px;
  top: 22px;
  cursor: pointer;
  padding: 10px 35px 16px 0px;
  background-color: transparent;
  span,
  span:before,
  span:after {
    cursor: pointer;
    border-radius: 1px;
    height: 5px;
    width: 24px;
    background: $main_green;
    position: absolute;
    display: block;
    content: "";
    transition: all 300ms ease-in-out;
  }
  span:before {
    top: -10px;
    width: 37px;
  }
  span:after {
    width: 15px;
    bottom: -10px;
  }
  &.active span {
    width: 35px;
    background-color: transparent;

    &:before,
    &:after {
      width: 35px;
      top: 0;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
}

@media screen and (min-width: 1270px) {
  .nav-list {
    display: flex !important;
  }
}
