main {
  color: whitesmoke;
  padding: 5%;
  overflow-y: scroll;


  &-about::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;  
}
