.form-container {
    max-width: 600px;
    margin: 100px auto;
    background: #1e1e1e;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    color: #ffffff;
}

h2 {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #ffffff;
}

form {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

input {
    width: 100%;
    padding: 12px;
    border: 1px solid #444;
    border-radius: 6px;
    background: #333;
    color: white;
    font-size: 16px;
    transition: all 0.3s ease;
}

input:focus {
    outline: none;
    border-color: #00ff11;
    box-shadow: 0 0 5px rgba(0, 255, 42, 0.5);
}

.border {
    border: 1px solid #444;
    border-radius: 6px;
    padding: 10px;
    background: #222;
}

.signature-canvas {
    width: 100%;
    height: 120px;
    border: 1px solid #ccc;
    background: white;
    border-radius: 6px;
}
.button {
    padding: 12px;
    font-size: 16px;
    border-radius: 6px;
    cursor: pointer;
    transition: background 0.3s ease, transform 0.2s ease;
    text-align: center;
    font-weight: bold;
}

.button-clear {
    background: #ff7c7c;
    border: 1px solid #ff7c7c;
    color: white;

    &:hover {
        background: #c55555;
    }

    &:active {
        transform: scale(0.95);
    }
}

.button-submit {
    left: 0;
    right: 0;
    margin-top: 50px;
    align-self: center;
    background: #00ff11;
    border: 1px solid #0fb300;
    color: white;

    &:hover {
        background: #09b300;
    }

    &:active {
        transform: scale(0.95);
    }
}