:root{
    --textShadow: rgba(35, 35, 35, 0.23);
    --textShadowGreen: rgba(7, 82, 0, 0.143);
}

.shadowText{
    text-shadow: 1px 1px 1px var(--textShadow),
    1px 2px 1px var(--textShadow),
    1px 3px 1px var(--textShadow),
    1px 4px 1px var(--textShadow),
    1px 5px 1px var(--textShadow),
    1px 6px 1px var(--textShadow),
    1px 7px 1px var(--textShadow),
    1px 8px 1px var(--textShadow),
    1px 9px 1px var(--textShadow),
    1px 10px 1px var(--textShadow),
      1px 18px 6px rgba(16,16,16,0.4),
      1px 22px 10px rgba(16,16,16,0.2),
      1px 25px 35px rgba(16,16,16,0.2),
      1px 30px 60px rgba(16,16,16,0.4),
      0 0 3px #000000, 0 0 8px #ffffff;
  }
  
.textCenter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 40vh;
    font-size: 6vw; 
    font-family: inherit;
    font-weight: 800;
    font-stretch: extra-expanded;
    margin: auto;
    margin-bottom: 25vh;
    color: white;
    text-align: center;

    @media (max-width: 480px) {
        font-size: 8vw; /* Slightly larger for better readability on smaller screens */
        margin-bottom: 20vh;
    }
    
    /* Small Devices (tablets in portrait) */
    @media (max-width: 768px) and (min-width: 481px) {
        font-size: 7vw; /* Adjust font size proportionally */
        margin-bottom: 22vh;
    }
    
    /* Medium Devices (tablets in landscape) */
    @media (max-width: 1024px) and (min-width: 769px) {
        font-size: 6.5vw;
        margin-bottom: 24vh;
    }
    
    /* Large Devices (desktops and larger screens) */
    @media (min-width: 1025px) {
        font-size: 6vw;
        margin-bottom: 25vh; /* Use original settings for larger screens */
    }
}


.despreTitlu {
  font-stretch: extra-expanded;
  font-family: inherit;
  font-size: 4vw; /* Responsive font size */
  font-weight: 800;
  color: inherit;
  text-align: center;
  padding-bottom: 3vh; /* Relative to viewport height */
  align-self: center;
  margin-right: 2vw; /* Adjusted to scale with screen width */

    @media (max-width: 1200px) {
        font-size: 4rem; 
    }
    
    @media (max-width: 992px) {
        font-size: 3rem;
    }

    @media (max-width: 768px) {
        font-size: 2.5rem; 
    }
}

#despreText {
    font-family: inherit;
    font-stretch: semi-expanded;
    font-size: 1em;
    color: rgba(255, 255, 255, 0.87);
    text-align: center;
    margin: 0;
    line-height: 1.5;
    padding: 10px;
    white-space: normal;
    word-wrap: break-word; 
    overflow-wrap: break-word;

    @media (max-width: 1200px) {
        font-size: 1rem; 
    }
    
    @media (max-width: 992px) {
        font-size: 0.8rem;
    }

    @media (max-width: 768px) {
        font-size: 0.8rem; 
    }
}